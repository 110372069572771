import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/opt/buildhome/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/arrow.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/arrowdown.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/arrowup.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/banner-overlay.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/error.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/facebook.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/footer-logo.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/instagram.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/left-arrow.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/live-chat.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/logo.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/menu-close.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/menu-open.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/pause-icon.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/play-icon.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/right-arrow.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/search.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/spining.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/twitter.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/zoom-in.svg");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/svg/zoom-out.svg");
